<template>
  <div class="float_btn_pencil">
    <div class="float_btn_pencil_content">
      <dialogtitle :text="'请填写信息'" @handleClose="handleClose" />
      <div class="form_wrp">
        <el-form
          ref="ruleForm"
          :model="ruleForm"
          :rules="rules"
          label-width="70px"
          class="demo-ruleForm"
        >
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="姓名" prop="name">
                <el-input
                  v-model="ruleForm.name"
                  placeholder="请输入您的姓名"
                />
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="职务">
                <el-input
                  v-model="ruleForm.duty"
                  placeholder="请输入您的职务"
                />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row class="mt-20" :gutter="20">
            <el-col :span="12">
              <el-form-item label="电话" prop="phone">
                <el-input
                  v-model="ruleForm.phone"
                  placeholder="请输入联系电话"
                />
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="验证码" prop="verification">
                <el-input
                  v-model="ruleForm.verification"
                  placeholder="请输入验证码"
                >
                  <template slot="suffix">
                      <el-button @click="getVerificationCode(el)" type="text" :disabled="disabledCode">{{verificationText}}</el-button>
                  </template>
                </el-input>
              </el-form-item>
            </el-col>
            </el-row>
            <el-row class="mt-20" :gutter="20">
            <el-col :span="24">
              <el-form-item prop="email" label="邮箱">
                <el-input
                  v-model="ruleForm.email"
                  placeholder="请输入邮箱地址"
                />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row class="mt-20 el_li" :gutter="20">
            <el-col :span="24">
              <el-row>
                <el-col :span="10">
                  <el-form-item label="地址" prop="provinceValue">
                    <el-select
                      v-model="ruleForm.provinceValue"
                      class="elselect"
                      placeholder="省份"
                      @change="provinceChange"
                    >
                      <el-option
                        v-for="(item, index) in province"
                        :key="index"
                        :label="item.label"
                        style="padding: 0 20px"
                        :value="index"
                      />
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="7">
                  <el-form-item class="text-center ml-20" prop="cityValue">
                    <el-select
                      v-model="ruleForm.cityValue"
                      :disabled="cityStatus"
                      class="elselect"
                      placeholder="城市"
                      @change="cityChange"
                    >
                      <el-option
                        v-for="(item, index) in city"
                        :key="index"
                        :label="item.label"
                        style="padding: 0 20px"
                        :value="index"
                      />
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="7">
                  <el-form-item class="ml-20" prop="countyValue">
                    <el-select
                      v-model="ruleForm.countyValue"
                      :disabled="countyStatus"
                      class="elselect"
                      placeholder="区县"
                      @change="countyChange"
                    >
                      <el-option
                        v-for="(item, index) in county"
                        :key="index"
                        :label="item.label"
                        style="padding: 0 20px"
                        :value="index"
                      />
                    </el-select>
                  </el-form-item>
                </el-col>
              </el-row>
            </el-col>
          </el-row>
          <el-row class="mt-20" :gutter="20">
            <el-col :span="24">
              <el-form-item label="公司">
                <el-input
                  v-model="ruleForm.company"
                  placeholder="请输入公司名称"
                />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row class="mt-20" :gutter="20">
            <el-col :span="24">
              <el-form-item label="其他">
                <el-input
                  v-model="ruleForm.more"
                  type="textarea"
                  :rows="5"
                  placeholder="其他您想要告诉我们的"
                />
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
      <div class="submit_btn" @click="submitForm('ruleForm')">立即下载</div>
    </div>
  </div>
</template>

<script>
import dialogtitle from '@/components/newFloatBtn/components/dialogtitle.vue'
import areac from '@/assets/js/areac'

var checkEmail = (rule, value, cb) => {
  const regEmail = /^([a-zA-Z]|[0-9])(\w|\-)+@[a-zA-Z0-9]+\.([a-zA-Z]{2,4})$/
  if (regEmail.test(value)) {
    // 合法的邮箱
    return cb()
  }
  cb(new Error('邮箱格式不正确'))
}

var checkPhone = (rule, value, cb) => {
  const regEmail = /^1\d{10}$/
  if (regEmail.test(value)) {
    // 合法的邮箱
    return cb()
  }
  cb(new Error('电话格式不正确'))
}

export default {
  name: 'FloatBtnPencil',
  components: { dialogtitle },
  data() {
    return {
      pendding: false,
      province: [],
      provinceIndex: null, // 省的下标
      city: [],
      cityIndex: null, // 城市的下标
      cityStatus: true,
      county: [],
      countyStatus: true,
      countyIndex: null, // 区县的下标
      ruleForm: {
        name: '',
        duty: '',
        phone: '',
        verification:'',
        email: '',
        company: '',
        more: '',
        provinceValue: '',
        provinceCode: '',
        cityValue: '',
        cityCode: '',
        countyValue: '',
        address: '',
        areaCode: ''
      },
      rules: {
        name: [{ required: true, message: '请输入您的姓名', trigger: 'blur' }],
        phone: [
          { required: true, message: '请输入您的联系电话', trigger: 'blur' },
           { validator: checkPhone, trigger: 'blur' }
        ],
        verification: [
          { required: true, message: '请输入验证码', trigger: 'blur' },
        ],
        email: [
          { required: true, message: '请输入邮箱', trigger: 'blur' },
          { validator: checkEmail, trigger: 'blur' }
        ],
        provinceValue: [
          { required: true, message: '请选择您的省份', trigger: 'change' }
        ],
        cityValue: [
          { required: true, message: '请选择您的城市', trigger: 'change' }
        ],
        countyValue: [
          { required: true, message: '请选择您的区县', trigger: 'change' }
        ]
      },
      verificationText:'获取',
      disabledCode:false,
    }
  },
  props: {
     VERSION_LSBLJ: {
        type: String,
        default: '',
      },
  },
  mounted() {
    this.getCity()
  },
  methods: {
    handleClose() {
      this.$emit('handleClose', 'pencil')
    },

    // 点击选中县城
    countyChange(val) {
      this.ruleForm.countyValue = this.county[val].label
      this.ruleForm.areaCode = this.county[val].value
    },
    // 点击选中市
    cityChange(val) {
      this.cityIndex = val
      this.ruleForm.countyValue = ''
      this.ruleForm.areaCode = ''
      this.county = this.province[this.provinceIndex].children[val].children
      this.countyStatus = false
      this.ruleForm.cityValue = this.city[val].label
      this.ruleForm.cityCode = this.city[val].value
    },
    // 点击选中省
    provinceChange(val) {
      this.provinceIndex = val
      this.county = ''
      this.ruleForm.cityValue = ''
      this.ruleForm.countyValue = ''
      this.ruleForm.cityCode = ''
      this.ruleForm.areaCode = ''
      this.city = this.province[val].children
      this.cityStatus = false
      this.ruleForm.provinceValue = this.province[val].label
      this.ruleForm.provinceCode = this.province[val].value
    },
    // 获取三级联查的各个地区
    getCity() {
      this.province = areac.data.filter((item) => [item.value, item.label])
    },
    // 马上加入
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (!this.padding) {
            this.padding = false
            this.$ajax({
              url: '/je/product/crm/customer/saveSaleLead',
              data: {
                typeCode: 'jepaas_xz',
                name: this.ruleForm.name,
                code:this.ruleForm.verification,
                telePhone: this.ruleForm.phone,
                corporateName: this.ruleForm.company,
                provinceName: this.ruleForm.provinceValue,
                provinceCode: this.ruleForm.provinceCode,
                cityName: this.ruleForm.cityValue,
                cityCode: this.ruleForm.cityCode,
                areaName: this.ruleForm.countyValue,
                areaCode: this.ruleForm.areaCode,
                duties: this.ruleForm.duty,
                email: this.ruleForm.email,
                describe: this.ruleForm.more
              }
            })
              .then((data) => {
                if(!data.data.success){
                  return this.$message.error(data.data.message);
                }
                this.padding = false
                this.ruleForm.name = ''
                this.ruleForm.phone = ''
                this.ruleForm.verification = '';
                this.ruleForm.company = ''
                this.ruleForm.provinceValue = ''
                this.ruleForm.provinceCode = ''
                this.ruleForm.cityValue = ''
                this.ruleForm.cityCode = ''
                this.ruleForm.countyValue = ''
                this.ruleForm.areaCode = ''
                this.ruleForm.duty = ''
                this.ruleForm.email = ''
                this.ruleForm.more = ''
                this.$refs[formName].resetFields()
                this.dialogFormVisible = false

                window.open(this.VERSION_LSBLJ)

                this.$message.success('提交成功!');
                this.$emit('handleClose');
              })
              .catch(() => {
                this.padding = false
                this.$message.error('提交失败，请联系销售人员!');
              })
          }
        } else {
          this.$message.error('请将所有必填信息填写完整')
          return false
        }
      })
    },
     //获取验码
    getVerificationCode(e){
      var that = this,
      timer = null,
      count = 60;
      if(this.ruleForm.phone == '' || this.ruleForm.phone == undefined){
         this.$message.error('请输入手机号!','error');
         return false;
      }

      if (that.verificationText == '获取') {
        that.disabledCode = true;

        this.$ajax({
              url: '/jepaas/login/greenPackSendCode',
              data: {
                telePhone:this.ruleForm.phone,
              },
            }).then((data) => {
              if(data.data.success){
                this.$message.success(data.data.message);
              }else{
                this.$message.error(data.data.message);
              }
            }).catch((e) => {
               this.$message.error('验证码发送失败!');
            });

        timer = setInterval(function(){
          count--;
          that.verificationText = count+'S';
        if (count <=0) {
          clearInterval(timer);
          that.verificationText='获取';
          that.disabledCode = false;
        }
        console.log(that.verificationText);
       },1000);
      }
    }
  }
}
</script>

<style lang="less">
.float_btn_pencil {
  padding: 35px 60px 70px;
  p,
  div {
    margin: 0;
    text-align: left;
  }
  .float_btn_pencil_content {
    width: 500px;
    .form_wrp {
      width: 100%;
      margin-top: 40px;
      .mt-20 {
        margin-top: 20px;
      }
      .ml-20 {
        .el-form-item__content {
          margin-left: 20px !important;
        }
      }
      .elselect {
        input {
          height: 40px !important;
        }
      }
    }
    .submit_btn {
      width: 200px;
      height: 50px;
      cursor: pointer;
      background: #ff3041;
      color: #ffffff;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 18px;
      margin: 40px auto 0;
      box-shadow: 2px 2px 10px 0px rgba(255, 48, 65, 0.4);
      border-radius: 25px;
    }
  }
}
</style>
