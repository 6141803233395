<template>
  <div class="jepaas_download page_box">
    <div class="box_top">
      <Header class="page_hea" />
    </div>
    <div class="page__content">
      <div class="section">
        <div class="content_box download">
          <img v-lazy="dl_book" alt />
          <div class="url_box">
            <p class="title">下载JEPaaS</p>
            <p class="subtitle">领先的信息化中台工具 SaaS快速开发领导者</p>
            <div class="btn_box">
              <p class="subtitle">
                绿色版
              </p>
              <div class="btn_wrp">
                <span @click="goDowGreenVersion()" class="btn download">绿色版下载</span>
                <!-- <span @click="goDow(3)" class="btn cancel">后端项目</span> -->
              </div>
              <p class="subtitle">商业版
                <el-button type="text" class="moreVs">
                  <a href="#historyVs">更多版本</a>
                </el-button>
              </p>
              <div class="btn_wrp">
                <!-- <span class="btn cancel" @click="goDow(3)">前往Gitee</span> -->
                <span @click="goDow(2)" class="btn cancel">后端项目</span>
                <span @click="goDow(4)" class="btn cancel">前端项目</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="historyVs" class="section" />
      <div class="section history-version" style="margin-top: 15px;">
        <el-tabs v-model="activeName" @tab-click="handleTabClick">
          <el-tab-pane label="平台历史版本" name="first">
            <el-table :data="historyVersion" stripe max-height="500" style="width: 100%">
              <el-table-column
                label="序号"
                align="center"
                header-align="center"
                type="index"
                width="50"
              />
              <el-table-column
                prop="version"
                align="center"
                header-align="center"
                show-overflow-tooltip
                label="版本号"
              />
              <el-table-column
                prop="download"
                label="下载"
                align="center"
                header-align="center"
                show-overflow-tooltip
                width="220"
              >
                <template slot-scope="scope">
                  <el-button
                    @click="handleDownload(scope.row)"
                    class="downloadBtn"
                    type="text"
                    icon="jeicon jeicon-download"
                    size="small"
                  >{{ scope.row.download }}</el-button>
                </template>
              </el-table-column>
              <el-table-column label="说明" align="center" header-align="center" width="140">
                <template slot-scope="scope">
                  <el-button @click="handleClick(scope.row)" type="text" size="small">查看详情</el-button>
                </template>
              </el-table-column>
              <template slot="empty" v-if="isNoData">
                <div style="padding: 50px; text-align: center">
                  <img src="../../detail/assets/images/alreadyIndent/nullData.png" alt />
                  <div class="color-BEBEBE">暂无数据</div>
                </div>
              </template>
            </el-table>
          </el-tab-pane>
          <!-- <el-tab-pane
            label="平台历史升级包"
            name="second"
            lazy
          >
            <el-table
              :data="historyPackage"
              stripe
              max-height="250"
              style="width: 100%"
            >
              <el-table-column
                label="序号"
                align="center"
                header-align="center"
                type="index"
                width="50"
              />
              <el-table-column
                prop="version"
                align="center"
                header-align="center"
                show-overflow-tooltip
                label="版本号"
              />
              <el-table-column
                align="center"
                header-align="center"
                show-overflow-tooltip
                label="下载"
                width="220"
              >
                <template slot-scope="scope">
                  <el-button
                    @click="handleDownload(scope.row)"
                    class="downloadBtn"
                    type="text"
                    icon="jeicon jeicon-download"
                    size="small"
                  >
                    {{ scope.row.download }}
                  </el-button>
                </template>
              </el-table-column>
              <el-table-column
                label="说明"
                align="center"
                header-align="center"
                width="140"
              >
                <template slot-scope="scope">
                  <el-button
                    @click="handleClick(scope.row)"
                    type="text"
                    size="small"
                  >
                    查看详情
                  </el-button>
                </template>
              </el-table-column>
              <template
                slot="empty"
                v-if="isNoData"
              >
                <div style="padding: 50px; text-align: center">
                  <img
                    src="../../detail/assets/images/alreadyIndent/nullData.png"
                    alt
                  >
                  <div class="color-BEBEBE">
                    暂无数据
                  </div>
                </div>
              </template>
            </el-table>
          </el-tab-pane>-->
        </el-tabs>

        <div v-if="totalCount > 20" class="history-table-pagination">
          <el-pagination
            :page-size="20"
            :total="totalCount"
            @current-change="pageHandleChange"
            background
            layout="prev, pager, next, jumper"
          />
        </div>
      </div>
      <div class="section">
        <div class="content_box show">
          <div class="linear_bg">
            <p>JEPaaS精彩画册</p>
            <span @click="dialogVisible = !dialogVisible" class="yuding">马上预订</span>
          </div>
          <div class="show_box">
            <div v-for="(item, index) in showtext" :key="index">
              <p :style="{marginTop:index==0?'0px':''}" class="title">{{ item.title }}</p>
              <p v-if="item.subtitle" class="subtitle">{{ item.subtitle }}</p>
              <div v-else class="img_wrp">
                <img v-for="(img, i) in item.imglist" :key="i" v-lazy="img" alt />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div @click="dialogVisible = !dialogVisible" class="yuding outer">马上预订</div>
    </div>
    <el-dialog
      :show-close="false"
      :visible.sync="dialogVisible"
      width="581px"
      height="400px"
      class="order_dialog"
    >
      <p class="title">订阅热线</p>
      <p class="phone">
        <img :src="phone" alt />
        <span>石小姐</span>
        <span>15010612217</span>
      </p>
    </el-dialog>
    <Footer />

    <div
      v-if="show"
      class="float_btn_dialog_wrp"
    >
      <div
        class="dialog"
      >
        <Pencli
        :VERSION_LSBLJ="historyVersion[0].VERSION_LSBLJ"
        @handleClose="handleClose"
      />
      </div>
    </div>
  </div>
</template>

<script >
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'

import Pencli from '@/pages/index/views/pencil.vue';

import bg_pr from '@/assets/imgs/download/bg_pr.png'
import dl_book from '@/assets/imgs/download/dl_book.png'
import dl1 from '@/assets/imgs/download/dl1.jpg'
import dl2 from '@/assets/imgs/download/dl2.jpg'
import dl3 from '@/assets/imgs/download/dl3.jpg'
import dl4 from '@/assets/imgs/download/dl4.jpg'
import dl5 from '@/assets/imgs/download/dl5.jpg'
import dl6 from '@/assets/imgs/download/dl6.jpg'
import dl7 from '@/assets/imgs/download/dl7.jpg'
import dl8 from '@/assets/imgs/download/dl8.jpg'
import dl9 from '@/assets/imgs/download/dl9.png'
import dl10 from '@/assets/imgs/download/dl10.png'
import phone from '@/assets/imgs/download/phone.png'
import { isArray } from '@/util/util'
import {
  getHistoryVersion,
  getHistoryPackage
} from '@/pages/index/actions/actions'
// import { POST_DOWNLOAD_UPGRADE_PACKAGE } from '@/pages/index/actions/api';

export default {
  name: 'ProductPage',
  components: {
    Header,
    Footer,
    Pencli
  },
  data() {
    return {
      activeName: 'first',
      bg_pr,
      dl_book,
      showtext: [
        {
          title: '产品介绍',
          subtitle:
            '国内主流paas平台架构拆解及详细说明，以及国内主流saas产品功能应用详解。该画册融合了10年业务沉淀经验，从paas到saas的技术架构、业务架构，以及应用。通过paas层的强大支撑，到saas级业务的全面应用，从开发逻辑、思维、以及应用及运营进行了清晰地阐述。同时，也显示了JEPaaS云平台的强大功能及未来业务发展的无限想象。'
        },
        {
          title: '适读人群',
          subtitle:
            '软件开发公司、软件开发人员及主管，帮助您更快更精准地了解JEPaaS的强大功能。'
        },
        {
          title: '价格',
          subtitle: '100元/套（包括JEPaaS+蒜瓣SCRM+光盘）'
        },
        {
          title: '部分内容展示（JEPaaS）',
          imglist: [dl1, dl2, dl3, dl4]
        },
        {
          title: '部分内容展示（蒜瓣SCRM）',
          imglist: [dl5, dl6, dl7, dl8]
        },
        {
          title: '光盘',
          imglist: [dl9, dl10]
        }
      ],
      dialogVisible: false,
      phone,
      historyVersion: [],
      historyPackage: [],
      totalCount: 0,
      versionDetail: '',
      currPage: 1,
      isNoData: false,
      show: false,
    }
  },
  created() {
    this.getHistoryVersionData()
  },
  methods: {
    handleClick(row) {
      if (!row.VERSION_XQLJ) {
        return
      }
      window.open(
        row.VERSION_XQLJ.startsWith('http')
          ? row.VERSION_XQLJ
          : 'https://gitee.com/ketr/jepaas-commercial/tags'
      )
    },
    handleTabClick(tab) {
      if (tab.name == 'first') {
        this.getHistoryVersionData()
      } else if (tab.name == 'second') {
        this.getHistoryPackageData()
      }
    },
    // 获取历史版本数据
    getHistoryVersionData() {
      this.isNoData = false
      getHistoryVersion({
        pageSize: 20,
        currPage: this.currPage
      }).then((result) => {
        if (!result.success) {
          return this.$message({
            type: 'error',
            message: result.message
          })
        }
        this.totalCount = result.totalCount || 0
        if (this.totalCount == 0) {
          this.isNoData = true
        }
        const list = isArray(result.rows) ? result.rows : []
        list.forEach((item, index) => {
          list[index].version = item.VERSION_PTBB_NAME
          list[index].download = `zip(${item.VERSION_XZBDX || '未知'})` // TODO 此处大小 后台没有返回
        })
        this.historyVersion = list
      })
    },
    // 获取历史升级包数据
    getHistoryPackageData() {
      this.isNoData = false
      getHistoryPackage({
        pageSize: 20,
        currPage: this.currPage
      }).then((result) => {
        if (!result.success) {
          return this.$message({
            type: 'error',
            message: result.message
          })
        }
        this.totalCount = result.totalCount || 0
        if (this.totalCount == 0) {
          this.isNoData = true
        }
        const list = isArray(result.rows) ? result.rows : []
        list.forEach((item, index) => {
          list[index].version = item.VERSION_PTBB_NAME
          list[index].download = `zip(${item.VERSION_XZBDX || '未知'})` // TODO 此处大小 后台没有返回
        })
        this.historyPackage = list
      })
    },
    // 下载
    handleDownload(row) {
      window.open( '/je/doc/download?path=' + row.VERSION_FUNCINFO)
    },
    // 点击分页
    pageHandleChange(val) {
      this.currPage = val
    },
    goDowGreenVersion() {
      this.show = true
    },
    handleClose() {
      this.show = false
    },
    goDow(e) {
      if (e === 1) {
        // this.$message.success('正在升级,敬请期待!')
        // window.open('http://pm.jepaas.com/JEPAAS-Green-7.2.6.zip')
        window.open(this.historyVersion[0].VERSION_LSBLJ)
      } else if (e == 2) {
        // window.open('https://gitee.com/ketr/jepaas-commercial', 'gitees');
        window.open('https://gitee.com/ketr/jepaas-release', 'gitees');
        // this.$message.success('敬请期待!')
      } else if (e == 3) {
        window.open('https://gitee.com/ketr/jepaas-opensource', 'giteel')
        // this.$message.success('敬请期待!')
      } else if (e == 4) {
        window.open(
          'https://gitee.com/ketr/jepaas-project-frontend',
          'giteeq'
        )
      }
    }
  }
}
</script>

<style scoped lang="less">
.float_btn_dialog_wrp .dialog {
  position: fixed;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  transform: translate(-50%, -50%);
}
</style>

<style lang='less' >
.jepaas_download.page_box {
  .box_top {
    width: 100%;
    height: 80px;
    background: linear-gradient(
      270deg,
      rgba(252, 103, 81, 1) 0%,
      rgba(246, 71, 77, 1) 100%
    );
  }
  .page__content {
    text-align: center;
    padding: 60px 0;
    .yuding {
      width: 180px;
      height: 54px;
      line-height: 58px;
      background: #ff3041;
      box-shadow: 0px 3px 10px 0px rgba(144, 7, 18, 0.5);
      color: #ffffff;
      border-radius: 27px;
      cursor: pointer;
      transition: all 500ms;
      &:hover {
        color: #ff3041;
        background: #ffffff;
      }
      &.outer {
        margin: 50px auto;
      }
    }
    .section {
      margin: 0 auto;
      &:not(:first-child) {
        margin-top: 100px;
      }
      .content_box {
        width: 1250px;
        margin: 0 auto;
        box-sizing: border-box;
        &.download {
          background: #eeeeee;
          display: flex;
          justify-content: space-between;
          padding: 40px 60px;
          > img {
            width: 382px;
            height: 524px;
            margin-left: 60px;
          }
          > .url_box {
            padding-top: 60px;
            p {
              margin: 0;
              width: 100%;
              text-align: left;
              color: #333333;
            }
            > .title {
              font-size: 40px;
            }
            .subtitle {
              font-size: 18px;
              margin-top: 30px;
              .moreVs {
                margin-left: 15px;
                a {
                  text-decoration: none;
                  color: #409eff;
                  &:hover {
                    color: #66b1ff;
                  }
                }
              }
            }
            > .btn_box {
              margin-top: 20px;
              .btn_wrp {
                display: flex;
                justify-content: flex-start;
                margin-top: 20px;
                .btn {
                  display: block;
                  width: 220px;
                  height: 60px;
                  line-height: 60px;
                  border-radius: 4px;
                  font-size: 24px;
                  box-sizing: border-box;
                  cursor: pointer;
                  transition: all 500ms;
                  &:not(:first-child) {
                    margin-left: 30px;
                  }
                  &.download {
                    background: #ff3041;
                    color: #ffffff;
                    &:hover {
                      background: #da332e;
                    }
                  }
                  &.cancel {
                    background: #ffffff;
                    border: 2px solid rgba(51, 51, 51, 1);
                    color: #333333;
                    &:hover {
                      background: rgba(0, 0, 0, 1);
                      color: #ffffff;
                    }
                  }
                }
              }
            }
          }
        }
        &.show {
          background: #f8f8f8;
          .linear_bg {
            background: linear-gradient(
              -90deg,
              rgba(255, 120, 84, 1) 0%,
              rgba(246, 71, 77, 1) 100%
            );
            box-shadow: 0px 10px 20px 0px rgba(255, 48, 65, 0.3);
            border-radius: 4px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 0 60px;
            > p {
              color: #ffffff;
            }
            > .yuding {
              color: #ff3041;
              background: #ffffff;
              transition: all 500ms;
              &:hover {
                background: #ff3041;
                color: #ffffff;
              }
            }
          }
          .show_box {
            padding: 40px 60px;
            p {
              text-align: left;
              margin: 0;
            }
            .title {
              color: #ff3041;
              font-size: 24px;
              font-weight: bold;
              margin-top: 40px;
            }
            .subtitle {
              font-size: 18px;
              color: #666666;
              margin-top: 20px;
            }
            .img_wrp {
              display: flex;
              flex-wrap: wrap;
              > img {
                width: 530px;
                height: 380px;
                margin-top: 20px;
                &:nth-child(2n) {
                  margin-left: 20px;
                }
              }
            }
          }
        }
      }
    }
    #historyVs {
      height: 80px;
      margin-top: 0;
    }
    .history-version {
      width: 1250px;
      margin: 0 auto;
      .el-tabs__item {
        font-size: 20px;
        font-weight: 600;
        &:hover {
          color: #f64b4c;
        }
      }
      .el-tabs__item.is-active {
        color: #f64b4c;
      }
      .el-tabs__active-bar {
        background-color: #f64b4c;
      }
      .el-tabs__nav-wrap::after {
        height: 0;
      }
      .history-table-pagination {
        margin-top: 15px;
        text-align: right;
        .el-pagination.is-background .el-pager li:not(.disabled).active {
          background-color: #f9504f;
        }
      }
      .downloadBtn {
        color: #444;
        .jeicon {
          font-size: 14px;
          color: #8d98ab;
        }
        &:hover {
          color: #3a8ee6;
          .jeicon {
            color: #3a8ee6;
          }
        }
      }
    }
  }
  .order_dialog {
    border-radius: 10px;
    .el-dialog__header {
      display: none;
      padding: 0;
    }
    .el-dialog__body {
      padding: 15px 60px;
      border-radius: 10px;
      > p {
        margin: 0;
        color: #333333;
      }
      .title {
        font-size: 22px;
        padding: 30px 0;
        border-bottom: 1px solid #cccccc;
      }
      .phone {
        padding: 100px 0;
        font-size: 40px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        > img {
          box-sizing: border-box;
          width: 50px;
          margin-right: 30px;
        }
        > span:last-child {
          margin-left: 15px;
          padding-top: 6px;
        }
      }
    }
  }
}
</style>
